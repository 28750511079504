import React, {useState, useEffect, useMemo} from "react"
import MainContent, { MainContentLoading } from "../Layout/MainContent"
import Title from "../Text/Title"
import PostFooter from "../News/PostFooter"
import { useGuestApi } from "../../hooks/useApi"
import { getTitle, getQualifio, getQualifioCampaignId ,getPublishOn, getQualifioParticipateLimit } from "../../helpers/Publications"
import withGuestLayout from "../../../src/hoc/withGuestLayout"
import { formatPageInformation } from "../../helpers/Meta"
import Qualifio from "../Qualifio/Qualifio"
import { returnLayoutSection } from "../../helpers/Layout"
import Error404 from "../Errors/404"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import global from "../../global/global";
import BtnRounded from "../Buttons/BtnRounded";
import {useOAuthTokens} from "../../hooks/useOAuthTokens";
import {formatMe} from "../../helpers/User";
import {useAccount} from "../../hooks/useAccount";

function ActionDetail({ slug }) {
  const { getPublicationBySlug, setPageInformation, getQualifioToken, getMeByAccessToken } = useGuestApi()
  const [actionItem, setActionItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [liveStream, setLiveStream] = useState(false);
  const [qualifioToken, setQualifioToken] = useState(null);
  const { accessToken } = useOAuthTokens()
  const {me} = useAccount()
  const [authenticatedUser, setAuthenticatedUser] = useState(null)

  useEffect(
    function fetchPublications() {
      getPublicationBySlug(slug).then(response => {
        setLoading(false)
        setPageInformation(formatPageInformation(response, "article"))
        setActionItem(response)
        if (window.opix && response) {
          opix("event", "View", { publication: response.id })
        }

        if (response === null) {
          navigate("/acties")
        }
      })
    },
    [slug]
  )

  useEffect(() => {
    if (actionItem?.livestream_url?.body) {
      setLiveStream(true)
    }
  }, [actionItem])

  useEffect(() => {
    if(accessToken && Object.keys(me).length === 0) {
      getMeByAccessToken(accessToken).then(response => {
        setAuthenticatedUser(formatMe(response));
      });
    } else if(me ? Object.keys(me).length !== 0 : false) {
      setAuthenticatedUser(me);
    }
  }, [accessToken, me])

  useEffect(() => {
    if(authenticatedUser && actionItem && getQualifioCampaignId(actionItem?.qualifio_campaign_id)) {
      const address = authenticatedUser?.addresses?.items?.length > 0 ? authenticatedUser.addresses.items[0] : null;
      const obj = {
        payload: {
          campaignId: getQualifioCampaignId(actionItem.qualifio_campaign_id),
          userId: authenticatedUser.id,
          limit: {
            nb: getQualifioParticipateLimit(actionItem.qualifio_participate_limit),
          },
          form: {
            firstname: authenticatedUser?.firstName,
            lastname: authenticatedUser?.lastName,
            email: authenticatedUser?.email,
            zipcode: address?.zipcode,
            country: address?.country,
            address: address?.street,
            number: address?.house_number,
            city: address?.city,
            locality: address?.city,
            birthday: authenticatedUser?.dateOfBirth,
            gender: authenticatedUser?.gender,
            phone: authenticatedUser?.phone?.slice(2),
          }
        }
      }
      getQualifioToken(obj).then((response) => {
        if(response.status === 200) {
          const { token } = response.data;
          setQualifioToken(token);
        }
      });
    }
  }, [authenticatedUser, actionItem]);

  return (
    <>
      {loading && <MainContentLoading />}
      {actionItem && (
        <MainContent {...actionItem} imageType={"image_website_detail"}>
          <div className="news__detail">
            <Title>{getTitle(actionItem.title)}</Title>
            {actionItem.layout
              ? Array.isArray(actionItem.layout.items)
                ? actionItem.layout.items.map((item, i) => {
                    return returnLayoutSection(item, i)
                  })
                : null
              : null}
            {actionItem.qualifio && (
              <>
                {authenticatedUser ?
                  <Qualifio src={`${getQualifio(actionItem.qualifio)}&qual_token=${qualifioToken}`}/> :
                  <div className="not-logged-in">
                    <p>
                      <strong>Aanmelden om deel te nemen </strong>
                      <br/>
                      Je moet aangemeld zijn om deel te nemen aan deze actie.
                      < br/>
                    </p>
                    <BtnRounded primary large
                                onClick={() => global.modal.setShowAuthModal(true)}>
                      Aanmelden
                    </BtnRounded>
                  </div>
                }
              </>
            )}
            {liveStream && (
                <>
                  <Helmet>
                    <script src="/livestream/player.js" async onload="playerLoaded();" />
                    <script>
                      {`
                        function playerLoaded() {
                          var player = new Playerjs({id:"fluidPlayer", file: "${actionItem.livestream_url.body}", poster:"/livestream/poster.jpg"});
                        }
                      `}
                    </script>
                  </Helmet>
                  <div className="livestream-player" id="fluidPlayer"></div>
                </>
              )}
            <PostFooter date={getPublishOn(actionItem.publish_on)} />
          </div>
        </MainContent>
      )}
      {!actionItem && !loading && <Error404 />}
    </>
  )
}

export default withGuestLayout(ActionDetail, {
  page: "Acties",
  shareImage: "",
  shareDescription: "",
})
