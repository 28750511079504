import React from "react"
import { Router } from "@reach/router"
import ActionsOverview from "../components/Actions/Overview"
import ActionDetail from "../components/Actions/Detail"

const Actions = () => {
  return (
    <Router>
      <ActionsOverview path="acties" />
      <ActionDetail path="acties/:slug" />
    </Router>
  )
}

export default Actions
