import React from "react"
import { useLocation } from "@reach/router"
import ActionsGrid from "../../components/Grid/ActionsGrid"
import Container from "../../components/Layout/Container"
import "./Overview.scss"

function ActionsOverview() {
  const history = useLocation()

  return (
    <Container page="Acties">
      <div className="actions container">
        <div className="actions__grid">
          <ActionsGrid history={history} />
        </div>
      </div>
    </Container>
  )
}

export default ActionsOverview
