import React, { useState, useEffect } from "react"
import Card from "../Card/Card"
import HomeModal from "../Modal/HomeModal"
import BtnRounded from "../Buttons/BtnRounded"
import "./Grid.scss"
import { useGuestApi } from "../../hooks/useApi"
import {
  getTitle,
  getSingleImage,
  convertToSlug,
  getModelType,
  getInstagramImage,
  getColor,
  convertToSlugWithOrigin,
} from "../../helpers/Publications"
import { formatPageInformation } from "../../helpers/Meta"
import { useTranslation } from "react-i18next"
import LoadingCard from "../Card/LoadingCard"
import GridEmpty from "./GridEmpty"
import { getImageType } from "../../helpers/Prepr"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 9,
}

export default function ActionsGrid({ history }) {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalClosing, setModalClosing] = useState(false)
  const [modalData, setModalData] = useState([])
  const [pagination, setPagination] = useState(initialPagination)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  const { getActionsPageGridPublications, setPageInformation } = useGuestApi()
  const [gridItems, setGridItems] = useState([])
  useEffect(fetchActionsPublications, [page])

  function fetchActionsPublications() {
    getActionsPageGridPublications(pagination).then(response => {
      setPagination({
        total: response.total,
        limit: response.limit,
        skip: response.skip,
      })
      setGridItems(gridItems.concat(response.grid.items))
      setLoading(false)
    })
  }

  const openModal = data => {
    const newUrl = convertToSlugWithOrigin(data.model_id, data.slug)
    window.history.pushState({ path: newUrl }, "", newUrl)
    document.body.classList.add("modal-active")
    setModalVisible(!modalVisible)
    setModalData(data)
    setPageInformation(formatPageInformation(data))
  }

  const closeModal = () => {
    document.body.classList.remove("modal-active")
    window.history.pushState("", "", history.pathname)
    setModalClosing(true)
    setPageInformation(null)
    setTimeout(() => {
      setModalVisible(!modalVisible)
      setModalClosing(!setModalClosing)
    }, 300)
  }

  function loadMore() {
    setIsLoading(true)
    if (gridItems.length < pagination.total) {
      setPagination({
        total: pagination.total,
        limit: pagination.limit,
        skip: gridItems.length,
      })
      setPage(page + 1)
      setIsLoading(false)
    }
  }
  return (
    <>
      {!gridItems.length && loading && (
        <>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </>
      )}
      {!gridItems.length && !loading && (
        <GridEmpty>{t("gridEmpty.actions")}</GridEmpty>
      )}
      {gridItems.map((item, index) => (
        <Card
          key={index}
          type={getModelType(item.model_id)}
          title={getTitle(item.title)}
          image={getSingleImage(
            getImageType(
              "image_website_block",
              item.image,
              null,
              item.image_website_block,
              null
            )
          )}
          instagramImage={getInstagramImage(item.instagram_post)}
          color={getColor(item.color)}
          route={convertToSlug(item.model_id, item.slug)}
          onClickCard={() => openModal(item)}
        />
      ))}
      <div className="grid--more">
        {gridItems.length < pagination.total && (
          <BtnRounded onClick={loadMore} secondary large loading={isLoading}>
            {t("list.loadMore")}
          </BtnRounded>
        )}
      </div>
      <HomeModal
        animating={modalClosing}
        visible={modalVisible}
        onClickClose={closeModal}
        data={modalData}
      />
    </>
  )
}
